import { Box } from '@material-ui/core';
import { ISectionComponentProps } from 'components/Sections/utils';
import { Headline2, Paragraph1 } from '@/shared/components/Typography';
import SecondaryButton from '@/shared/components/Button/SecondaryButton';
import { useImageStyles } from '../styles';

export default function MultipleImagesWithTextLayout(props: ISectionComponentProps) {
    const { section } = props
    const classes = useImageStyles()
    const imageAspectRatioType = section.customize?.format?.imageAspectRatio
    const alignmentType = section.customize?.format?.alignment

    return (
        <Box>
            <Headline2 className={`${classes.multipleImagesHeadLine} ${alignmentType === 'center' && classes.centerAlignmentTitle}`}>{section.name}</Headline2>
            <Box className={classes.multipleImagesContainer}>
                <Box pb={4} className={`${classes.multipleImagesWrapper} ${classes.multipleImagesWrapperCropping}`}>
                    {section.content?.map((image, index) => (
                            <div
                                key={`${image.image_name}-${index}`}
                                className={`${classes.multipleImagesItemWithTextCropping} 
                                    ${imageAspectRatioType === 'landscape' && classes.imageLandscape}
                                    ${imageAspectRatioType === 'square' && classes.imageSquare}   
                                    ${imageAspectRatioType === 'portrait' && classes.imagePortrait}`
                                }
                            >
                                <img src={image.src} className={`${classes.multipleImagesImage}`} alt='' />
                                <Box className={classes.imageTextWrapper}>
                                    <Paragraph1 className={`${classes.imageText} ${alignmentType === 'center' && classes.centerAlignmentTitle}`}><b>{image.text}</b></Paragraph1>
                                    {image.imageButtonLink && image.imageButtonLinkText && (
                                        <Box className={`${alignmentType === 'center' && classes.centerAlignmentTitle}`} mt={4}>
                                            <a className={classes.imageTextButtonLink} href={image.imageButtonLink || null} {...image?.open_in_new_window ? { target: '_blank', rel: 'noreferrer' } : {}}>
                                                <SecondaryButton
                                                    applyBackgroundColor
                                                >
                                                        {image.imageButtonLinkText}
                                                </SecondaryButton>
                                            </a>
                                        </Box>
                                    )}
                                </Box>
                            </div>
                    ))}
                </Box>
            </Box>
            <Box className={classes.multipleImagesWithTextWrapper}>
                {section.content?.map((image, index) => (
                    <div key={index} className={`${classes.multipleImagesWrapper} ${classes.multipleImagesWrapperCropping} ${classes.multipleImagesWithTextItem}`}>
                        <Paragraph1 className={`${classes.imageText} ${alignmentType === 'center' && classes.centerAlignmentTitle}`}><b>{image.text}</b></Paragraph1>
                        {image.imageButtonLink && image.imageButtonLinkText && (
                            <Box className={`${alignmentType === 'center' && classes.centerAlignmentTitle}`}>
                                <a className={classes.imageTextButtonLink} href={image.imageButtonLink || null} {...image?.open_in_new_window ? { target: '_blank', rel: 'noreferrer' } : {}}>
                                    <SecondaryButton
                                        applyBackgroundColor
                                    >
                                        {image.imageButtonLinkText}
                                    </SecondaryButton>
                                </a>
                            </Box>
                        )}
                    </div>
                ))}
            </Box>
        </Box>
    )
}
