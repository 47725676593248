import { makeStyles } from '@material-ui/core'

export const useImageStyles = makeStyles((theme) => ({
    full_width: {
        '& $imageContainer': {
            position: 'absolute',
            zIndex: -1,
            inset: 0,
            overflowY: 'hidden',  
        },
        width: '100%',
        height: '100vh',
        [theme.breakpoints.down('sm')]: {
            height: 244
        },
    },
    imageContainer: {
        '& img': {
            width: '100%',
            objectFit: 'cover',
            height: '100%',
        },
    },
    cropped: {
        height: 480,
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            height: 244
        },
        '& $imageContainer': {
            position: 'absolute',
            zIndex: -1,
            inset: 0,
            overflowY: 'hidden',
        },
    },
    with_border: {
        '& $imageContainer': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    },
    titleHeadline: {
        marginBottom: 24,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 16
        }
    },
    centerAlignmentTitle: {
        textAlign: 'center'
    },
    multipleImagesHeadLine: {
        marginBottom: '28px'
    },
    multipleImagesContainer: {
        display: 'flex',
    },
    multipleImagesWrapper: {
        width: '100%',
        display: 'flex',
        gap: '16px',
        paddingBottom: '16px',
        [theme.breakpoints.down('xs')]: {
            display: 'block',
        },
    },
    multipleImagesWithTextWrapper: {
        width: '100%',
        display: 'flex',
        gap: '16px',
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    multipleImagesWithTextItem: {
        flexDirection: 'column'
    },
    imageTextWrapper: {
        display: 'none',
        flexDirection: 'column',
        margin: '16px 0 32px',
        [theme.breakpoints.down('xs')]: {
            display: 'block',
        },
    },
    imageText: {
        fontSize: '16px',
    },
    imageTextButtonLink: {
        textDecoration: 'none'
    },
    multipleImagesWrapperCropping: {
        flexWrap: 'wrap',
    },
    multipleImagesItem: {
        flex: 'auto',
        [theme.breakpoints.down('xs')]: {
            marginBottom: '16px'
        }
    },
    multipleImagesItemCropping: {
        flex: '1 1',
        [theme.breakpoints.down('xs')]: {
            marginBottom: '16px'
        }
    },
    multipleImagesItemWithTextCropping: {
        flex: '1 1',
        position: 'relative',
        [theme.breakpoints.down('xs')]: {
            marginBottom: '16px'
        }
    },
    multipleImagesImage: {
        objectFit: 'cover',
        width: '100%',
        height: '100%',
    },
    multipleImagesImageMobileForSafari: {
        display: 'none',
        objectFit: 'cover',
        width: '100%',
        height: '100%',
        [theme.breakpoints.down('xs')]: {
            display: 'block'
        }
    },
    multipleImagesImageForSafari: {
        display: 'block',
        objectFit: 'cover',
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    maxHeightImage: {
        maxHeight: '544px',
        [theme.breakpoints.down('xs')]: {
            maxHeight: 'unset'
        }
    },
    imageContained: {
        objectFit: 'contain',
        objectPosition: 'top',
    },
    imageLandscape: {
        aspectRatio: '4/3'
    },
    imageSquare: {
        aspectRatio: '1/1'
    },
    imagePortrait: {
        aspectRatio: '3/4'
    }
}))
