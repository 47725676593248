import { extractFilenameWithoutExtension } from '@/shared/utils/helpers';
import { useMemo } from 'react';
import ResponsiveImage from './ResponsiveImage';

type IResponsiveImageSourceProps = {
    src: string
    alt?: string
    className?: string
    mime?: string
    style?: { [key: string]: any }
}
export default function ResponsiveImageSource(props: IResponsiveImageSourceProps) {
    const { src, mime } = props
    const sources = useMemo(() => {
        const filename = src.split('/').pop()
        const ext = filename.split('.').pop()
        const noExtensionFilename = extractFilenameWithoutExtension(filename)
        return [
            {
                width: 300,
                ext: 'webp',
                mime: 'image/webp',
                name: `small_${noExtensionFilename}.webp`
            },
            {
                width: 787,
                ext: 'webp',
                mime: 'image/webp',
                name: `big_${noExtensionFilename}.webp`
            },
            {
                width: 300,
                ext: ext,
                mime,
                name: `small_${noExtensionFilename}.${ext}`
            },
            {
                width: 787,
                ext: ext,
                mime,
                name: `big_${noExtensionFilename}.${ext}`
            },
        ].map(item => ({
            ...item,
            src: [...src.split('/').slice(0, -1), item.name].join('/'),
        }))
    }, [src])
    return (
        <ResponsiveImage
            sources={sources}
            {...props}
        />
    )
}