import { SectionLayoutType } from '@/shared/models/Section';
import { ISectionComponentProps } from '../utils';
import MultipleImagesLayout from './Layout/MultipleImagesLayout';
import PhotoLayout from './Layout/PhotoLayout';
import MultipleImagesWithTextLayout from './Layout/MultipleImagesWithTextLayout';

export default function ImageContainer(props: ISectionComponentProps) {
    if(props.section.layout === SectionLayoutType.MULTIPLE_IMAGES) {
        return <MultipleImagesLayout {...props}/>
    }
    if(props.section.layout === SectionLayoutType.MULTIPLE_IMAGES_WITH_TEXT) {
        return <MultipleImagesWithTextLayout {...props}/>
    }
    return <PhotoLayout {...props}/>
}