import React from 'react'

import { Box } from '@material-ui/core';
import { ISectionComponentProps } from 'components/Sections/utils';
import { useImageStyles } from '../styles';
import { Headline2 } from '@/shared/components/Typography';

export default function MultipleImagesLayout(props: ISectionComponentProps) {
    const { section } = props
    const classes = useImageStyles()
    const imageCroppingType = section.customize?.format?.imageCropping
    const imageCropping = imageCroppingType === 'crop' ? section.customize.format?.cropImagesTo : ''

    return (
        <Box>
            <Headline2 className={classes.multipleImagesHeadLine}>{section.name}</Headline2>
            <Box className={classes.multipleImagesContainer}>
                <div className={`${classes.multipleImagesWrapper} ${imageCroppingType === 'crop' && classes.multipleImagesWrapperCropping}`}>
                    {section.content?.map((image, index) => (
                        <div 
                            key={`${image.image_name}-${index}`}
                            className={`${imageCroppingType === 'crop' ? classes.multipleImagesItemCropping : classes.multipleImagesItem}
                                ${imageCropping === 'landscape' && classes.imageLandscape}
                                ${imageCropping === 'square' && classes.imageSquare}   
                                ${imageCropping === 'Portrait' && classes.imagePortrait}`
                            }
                        >
                            <a href={image?.url || null} {...image?.open_in_new_window ? { target: '_blank', rel: 'noreferrer' } : {}}>
                                <img src={image.src} className={`${classes.multipleImagesImage} ${imageCroppingType !== 'crop' ? classes.imageContained : ''} ${imageCroppingType !== 'crop' && section.content?.length > 2 ? classes.maxHeightImage : ''}`} alt='' />
                            </a>
                        </div>
                    ))}
                </div>
            </Box>
        </Box>
    )
}
