import { Box } from '@material-ui/core';
import { ISectionComponentProps } from 'components/Sections/utils';
import ResponsiveImageSource from '@/shared/components/Img/ResponsiveImageSource';
import { useImageStyles } from '../styles';
import { Headline3 } from '@/shared/components/Typography';
import { SectionLayoutType } from '@/shared/models/Section';

export default function PhotoLayout(props: ISectionComponentProps) {
    const { section, isPreview } = props
    const classes = useImageStyles()
    const source = section?.content?.src
    const linkProps = section?.content?.open_in_new_window || isPreview ? { target: '_blank', rel: 'noreferrer' } : {}
    const imageWidth = section.customize?.format.image_width || ''

    if (!source) {
        return null
    }

    return (
        <Box>
            {section.layout === SectionLayoutType.IMAGE_TITLE && (
                <Headline3 className={`${section.customize?.format?.alignment === 'center' && classes.centerAlignmentTitle} ${classes.titleHeadline}`}>{section.content?.image_title}</Headline3>
            )}
            <div className={section.layout !== SectionLayoutType.IMAGE_TITLE && classes[imageWidth]}>
                    <a href={section?.content?.url ?? null} {...linkProps}>
                        <div className={classes.imageContainer}>
                            <ResponsiveImageSource mime={section.content?.mime} src={source} style={{}} />
                        </div>
                    </a>
            </div>
        </Box>
    )
}
